import boltImage from "../assets/heroBolt.png";
import freeNowImage from "../assets/heroFreeNow.jpg";
import homeImage from "../assets/hero.jpg";
import offerImage from "../assets/heroOffer.jpg";
import uberImage from "../assets/heroUber.jpg";
import vehiclesImage from "../assets/heroVehicles.jpg";
import workImage from "../assets/heroWork.jpg";
import rental from "../assets/rental.jpg";

const HERO_DATA = {
  bolt: {
    id: "bolt",
    title: "Praca jako kierwoca Bolt w Dacar Partner",
    descr: [
      "Poniżej zestawione są informacje odnośnie wymagań, które musi spełniać osoba ubiegająca się o prace jako kierowca Bolt.",

      "Chronologiczne przedstawienie rejestracji w panelu Bolt.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: boltImage,
  },
  "free-now": {
    id: "free-now",
    title: "Praca jako kierwoca FreeNow w Dacar Partner",
    descr: [
      "Poniżej przedstawione zostały zalety pracy jako kierowca Free Now.",

      "Ukazane są wymagania jakie musi spełniać kierowca.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: freeNowImage,
  },
  home: {
    id: "home",
    title: "Czym jest Dacar Partner?",
    descr: [
      "Zajmujemysię pośredniczeniem w kilku popularnych firmach, które umożliwiają pracę oraz rozliczanie przez aplikacje mobilne, takie jak Uber, Bolt, FreeNow i inne.",

      "Niezależnieod tego, jakie aplikacje wybierzesz, zapewniamy rzetelne rozliczenie, terminowe przelewy oraz niskie prowizje za nasze usługi.",

      "Sprawdź jakie korzyści niesie współpraca z partnerem rozliczeniowym Dacar Partner i załóż konto już dziś.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: homeImage,
  },
  offer: {
    id: "offer",
    title: "Dostępne Oferty Dacar Partner",
    descr: [
      "Tutaj znajdziesz informacje dlaczego warto wybrać Dacar Partner.",

      "Poniżej opisane zostały platformy obsługiwane przez Dacar Partner.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: offerImage,
  },
  uber: {
    id: "uber",
    title: "Praca jako kierwoca Uber w Dacar Partner",
    descr: [
      "Poniżej zestawione są informacje odnośnie warunków, które musi spełniać osoba ubiegająca się o prace jako kierowca Uber.",

      "Rejesracja w panelu wyjaśniona krok po kroku.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: uberImage,
  },
  vehicles: {
    id: "vehicles",
    title: "Pojazdy Dacar Partner",
    descr: [
      "Tutaj możesz przeglądać dostępne pojazdy do wynajęcia.",

      "Wybierz odpowiedni dla siebie pojazd i skontaktuj się natychmiast!",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: vehiclesImage,
  },
  work: {
    id: "work",
    title: "Praca w Dacar Partner",
    descr: [
      "Poniżej znajdziesz informacje odnośnie pracy jako kierowca w firmach; Bolt, Uber, Free Now.",

      "Dowiesz się także jak krok po kroku aplikować na dane stanowisko.",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: workImage,
  },
  driver: {
    id: "work",
    title: "Kierowca w Dacar Partner",
    descr: [
      "Wykaz zysków jakie daje Dacar Partner kierowcom.",
      "Informacje dla przyszłych kierowców Dacar Partner. ",
    ],
    button: "SKONTAKTUJ SIĘ",
    imageUrl: rental,
  },
};

export default HERO_DATA;
