import styled from "styled-components";
import { SectionContainer } from "../../GlobalStyles";
import { COLORS } from "../../StyleVariables";

export const ErrorPageWrapper = styled.section`
  height: calc(100vh - 90px);
  background-color: ${COLORS["color-black-light"]};
`;
export const ErrorPageContainer = styled.div`
  ${SectionContainer};
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  color: ${COLORS["color-green-light"]};

  div {
    display: flex;
    align-items: center;
    font-size: 60px;

    svg {
      font-size: 58px;
    }
  }
`;

export const ErrorPageTitle = styled.h1`
  margin: 0 5px 0 0;
  font-weight: 600;
`;

export const ErrorPageDescr = styled.p`
  margin: 15px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: 200;
`;
