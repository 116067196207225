import { Grid } from "@mui/material";
import React from "react";
import Card from "../card/card.component";
import { BenefitsTitle } from "../benefits/benefits.styles";

import {
  CooperationCircle1,
  CooperationCircle3,
  CooperationCircle4,
  CooperationContainer,
  CooperationWrapper,
} from "./cooperation.styles";

const Cooperation = ({ data }) => {
  const { title, cardsList } = data;
  return (
    <CooperationWrapper>
      <CooperationContainer>
        <BenefitsTitle>{title}</BenefitsTitle>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {cardsList.map((card, index) => (
            <Grid
              key={index}
              item
              md={4}
              sm={index === 2 ? 12 : 6}
              xs={12}
              style={{ zIndex: "1" }}
            >
              <Card data={card} />
            </Grid>
          ))}
        </Grid>

        <CooperationCircle1 />
        <CooperationCircle3 />
        <CooperationCircle4 />
      </CooperationContainer>
    </CooperationWrapper>
  );
};

export default Cooperation;
