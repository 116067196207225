import React from "react";

import Benefits from "../../components/benefits/benefits.component";
import Contact from "../../components/contact/contact.component";
import Hero from "../../components/hero/hero.component";
import OurOffer from "../../components/our-offer/our-offer.component";

import BENEFITS_DATA from "../../data/benefits.data";
import HERO_DATA from "../../data/hero.data";

const Home = () => {
  return (
    <>
      <Hero data={HERO_DATA.home} />
      <OurOffer title={"Nasza Oferta"} />
      <Benefits data={BENEFITS_DATA} title={"Korzyści ze Współpracy"} />
      <Contact title={"Skontaktuj się"} />
    </>
  );
};

export default Home;
