const CardsActionTypes = {
  FETCH_CARDS_START: "FETCH_CARDS_START",
  FETCH_CARDS_SUCCESS: "FETCH_CARDS_SUCCESS",
  FETCH_CARDS_FAILURE: "FETCH_CARDS_FAILURE",
  ADD_CARD_START: "ADD_CARD_START",
  ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
  ADD_CARD_FAILURE: "ADD_CARD_FAILURE",
  EDIT_CARD_START: "EDIT_CARD_START",
  EDIT_CARD_SUCCESS: "EDIT_CARD_SUCCESS",
  EDIT_CARD_FAILURE: "EDIT_CARD_FAILURE",
  DELETE_CARD_START: "DELETE_CARD_START",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
};

export default CardsActionTypes;
