import { createSelector } from "reselect";

const selectCardsCollection = (state) => state.cardsCollection;

export const selectCards = createSelector(
  [selectCardsCollection],
  (cardsCollection) => cardsCollection.cards
);

export const selectCardsLength = createSelector([selectCards], (cards) =>
  cards ? Object.keys(cards).map((key) => cards[key]).length : null
);

export const selectIsCardsFetching = createSelector(
  [selectCardsCollection],
  (cardsCollection) => cardsCollection.isFetching
);

export const selectIsCardsLoaded = createSelector(
  [selectCardsCollection],
  (cardsCollection) => !!cardsCollection.cards
);

export const selectIsCardAdding = createSelector(
  [selectCardsCollection],
  (cardsCollection) => cardsCollection.isPosting
);

export const selectSuccessMessage = createSelector(
  [selectCardsCollection],
  (cardsCollection) => cardsCollection.successMessage
);
