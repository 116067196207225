import CardsActionTypes from "./cards.types";

export const fetchCardsStart = () => ({
  type: CardsActionTypes.FETCH_CARDS_START,
});

export const fetchCardsSuccess = (cardsMap) => ({
  type: CardsActionTypes.FETCH_CARDS_SUCCESS,
  payload: cardsMap,
});

export const fetchCardsFailure = (errorMessage) => ({
  type: CardsActionTypes.FETCH_CARDS_FAILURE,
  payload: errorMessage,
});

export const deleteCard = (card) => ({
  type: CardsActionTypes.DELETE_CARD,
  payload: card,
});

export const addCardStart = (card, firestore) => ({
  type: CardsActionTypes.ADD_CARD_START,
  payload: { card, firestore },
});

export const addCardSuccess = (card, successMessage) => ({
  type: CardsActionTypes.ADD_CARD_SUCCESS,
  payload: { card, successMessage },
});

export const addCardFailure = (error) => ({
  type: CardsActionTypes.ADD_CARD_FAILURE,
  payload: error,
});

export const editCardStart = (card, firestore) => ({
  type: CardsActionTypes.EDIT_CARD_START,
  payload: { card, firestore },
});

export const editCardSuccess = (card, successMessage) => ({
  type: CardsActionTypes.EDIT_CARD_SUCCESS,
  payload: { card, successMessage },
});

export const editCardFailure = (error) => ({
  type: CardsActionTypes.EDIT_CARD_FAILURE,
  payload: error,
});

export const deleteCardStart = (card, firestore) => ({
  type: CardsActionTypes.DELETE_CARD_START,
  payload: { card, firestore },
});

export const deleteCardSuccess = (card, successMessage) => ({
  type: CardsActionTypes.DELETE_CARD_SUCCESS,
  payload: { card, successMessage },
});

export const deleteCardFailure = (error) => ({
  type: CardsActionTypes.DELETE_CARD_FAILURE,
  payload: error,
});
