import styled, { css } from "styled-components";
import { COLORS } from "../../StyleVariables";

export const CustomInputContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 0 16px 0;
  width: 100%;

  &:last-of-type {
    margin: 0;
  }
`;

const isActive = css`
  top: -6.4px;
  left: 10px;
  scale: 0.8;
  background-color: ${COLORS["color-green-main"]};
  font-weight: 500;
`;

const isNotActive = css`
  top: 10px;
  left: 5px;
  scale: 1;
  background-color: transparent;
`;

export const CustomInputLabel = styled.label`
  color: ${COLORS["color-white-dark"]};
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  padding: 0 5px;
  transition: background-color 180ms ease-out, top 150ms ease-out,
    left 150ms ease-out, scale 150ms ease-out, color 150ms ease-out;
  pointer-events: none;
  border-radius: 3px;
  ${({ isFilled }) => (isFilled ? isActive : isNotActive)};
`;

const customInputStyling = css`
  width: calc(100% - 10px);
  color: ${COLORS["color-white-dark"]};
  font-weight: 400;
  font-size: 14px;
  background-color: transparent;
  padding: 0 4px;
  border-radius: 6px;
  caret-color: rgb(187, 187, 187);
  border: 1px solid rgb(187, 187, 187);
  transition: all 150ms ease-out;
  min-height: ${({ minHeight }) => (!!minHeight ? minHeight : "34px")};

  + label {
    color: ${({ isFilled }) =>
      isFilled ? COLORS["color-black-light"] : COLORS["color-white-dark"]};
    background-color: ${({ isFilled }) =>
      isFilled ? COLORS["color-white-dark"] : "transparent"};
  }

  &:active,
  &:focus-visible,
  &:focus {
    border: 1px solid ${COLORS["color-green-light"]};

    + label {
      color: ${({ isFilled }) =>
        isFilled ? COLORS["color-white-dark"] : COLORS["color-green-light"]};
      background-color: ${({ isFilled }) =>
        isFilled ? COLORS["color-green-main"] : "transparent"};
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(96, 96, 96) inset !important;
    -webkit-text-fill-color: ${COLORS["color-white-dark"]} !important;

    + label {
      ${isActive}
    }
  }
`;

const iconButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  background-color: rgba(103, 162, 111, 0.78);
  font-size: 15px;

  &:hover {
    background-color: rgba(123, 182, 131, 0.98);
  }
`;

export const CustomInputArea = styled.input`
  ${customInputStyling}
`;

export const CustomInputTogglePassword = styled.button`
  ${iconButtonStyle}
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(0, -50%);
`;
