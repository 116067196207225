import styled, { css } from "styled-components";

export const ForDriverWrapper = styled.section`
  background: linear-gradient(180deg, #393b44, #737b84);
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: auto;
  }
`;

export const ForDriverContainer = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 30px;
  position: relative;

  @media (max-width: 768px) {
    padding: 15px;
    width: calc(100% - 30px);
  }
`;

export const ForDriverContent = styled.div`
  display: flex;
  background: linear-gradient(
    to left top,
    rgba(248, 248, 248, 0.7),
    rgba(248, 248, 248, 0.8)
  );
  width: calc(100% - 60px);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    width: calc(100% - 30px);
  }
`;

export const ForDriverContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > div {
    flex-direction: row;
  }
`;

export const ForDriverSubtitle = styled.h2`
  color: #282828;
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
  margin: 0 0 15px 0;
`;

export const ForDriverDescr = styled.p`
  font-size: 18px;
  color: #525252;
  line-height: 24px;
  margin: 0 0 15px 0;
`;

export const ForDriverImg = styled.div`
  min-width: 300px;
  margin: 0 0 0 15px;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 768px) {
    margin: 15px 0 0 0;
    min-width: 200px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const BenefitsCircle = css`
  background: linear-gradient(135deg, #74d680, #67a26f);
  border-radius: 50%;
  position: absolute;
  width: 250px;
  height: 250px;
  z-index: 1;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
`;

export const BenefitsCircle1 = styled.div`
  ${BenefitsCircle}
  width: 350px;
  height: 350px;
  top: 140px;
  left: 70px;
`;

export const BenefitsCircle2 = styled.div`
  ${BenefitsCircle}
  width: 300px;
  height: 300px;
  bottom: 300px;
  right: 200px;
`;

export const BenefitsCircle3 = styled.div`
  ${BenefitsCircle}
  width: 160px;
  height: 160px;
  top: 180px;
  right: 20px;
`;

export const BenefitsCircle4 = styled.div`
  ${BenefitsCircle}
  width: 230px;
  height: 230px;
  left: 300px;
  bottom: 20px;
`;

export const PartnerButton = styled.button`
  max-width: 300px;
  background: linear-gradient(180deg, #74d680, #67a26f);
  border-radius: 10px;
  font-size: 22px;
  padding: 10px 20px;
  width: 100%;
  color: #ededed;
  font-weight: 600;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 150ms ease-out;
  margin: 30px 0 0 auto;
  text-align: center;

  &:hover {
    transform: translate(15px, 0);
    div {
      animation: rotateKeys 400ms ease-out forwards;
    }
  }

  div {
    margin: 0 0 0 10px;
    transition: all 150ms ease-out;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  @keyframes rotateKeys {
    0% {
    }
    1% {
      transform: scale(1) rotate(25deg);
    }
    50% {
      transform: scale(1.3) rotate(-25deg);
    }
    100% {
      transform: scale(1.3) rotate(0deg);
    }
  }

  @media (max-width: 768px) {
    margin: 15px 0 0;
    padding: 6px 15px;
    font-size: 16px;
    width: auto;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;
