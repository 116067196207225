import React, { useState } from "react";

import firebase from "firebase/compat/app";
import { firestore } from "../../firebase/firebase.utils";
import "firebase/compat/storage";

import { useDispatch, useSelector } from "react-redux";

import { FaTimes as Times } from "react-icons/fa";
import { FaSkyatlas as Tick } from "react-icons/fa";
import {
  selectCardsLength,
  selectSuccessMessage,
} from "../../redux/cards/cards.selectors";
import { addCardStart, editCardStart } from "../../redux/cards/cards.actions";
import {
  CardAddContainer,
  Buttons,
  SubmitButton,
  JustAButton,
  CardCloseIcon,
  CardInput,
  CardLayer,
  CardLayerInner,
  SuccessMessageContainer,
  CheckboxContainer,
  ImageContainer,
  CardInputContainer,
  FormContainer,
} from "./add-card.styles";

import "./add-card.styles.scss";
import { Checkbox, checkboxClasses } from "@mui/material";
import { COLORS } from "../../StyleVariables";

const CardAdd = ({
  handleCloseButtonClick,
  currentCard,
  showSuccessMessage,
  setShowSuccessMessage,
}) => {
  const storage = firebase.storage();
  const dispatch = useDispatch();
  const cardsObjLength = useSelector(selectCardsLength);
  const [image, setImage] = useState(null);
  const [imageToShowAfterChoosing, setImageToShowAfterChoosing] =
    useState(null);
  const successMessage = useSelector(selectSuccessMessage);
  const [imageSuccessMessage, setImageSuccessMessage] = useState("Brak obrazu");
  const [progress, setProgress] = useState(0);
  const [card, setCard] = useState(
    !!currentCard
      ? {
          id: currentCard.id,
          brand: currentCard.brand,
          engine: currentCard.engine,
          fuel: currentCard.fuel,
          cost: currentCard.cost.split(" ")[0],
          deposit: currentCard.deposit.split(" ")[0],
          isAvailable: currentCard.isAvailable,
          imageUrl: currentCard.imageUrl,
        }
      : {
          id: cardsObjLength + 1,
          brand: "",
          engine: "",
          fuel: "",
          cost: "",
          deposit: "",
          isAvailable: true,
          imageUrl: "",
        }
  );

  const { brand, engine, fuel, cost, deposit, isAvailable, id, imageUrl } =
    card;
  //IMAGE
  const handleImageUpload = () => {
    const uploadTask = storage.ref(`images/${brand + id}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        storage
          .ref(`images/${brand + id}`)
          .getDownloadURL()
          .then((imageUrl) => {
            setCard({ ...card, imageUrl });
            setImageSuccessMessage("Obraz został wgrany");
          })
          .catch((err) => alert.log(err));
      }
    );
  };

  console.log(imageSuccessMessage); //TODO

  //POST
  const postDataHandler = async (event) => {
    event.preventDefault();

    if (!!currentCard) {
      dispatch(
        editCardStart(
          { ...card, cost: cost + " zł", deposit: deposit + " zł" },
          firestore
        )
      );
    } else {
      dispatch(
        addCardStart(
          { ...card, cost: cost + " zł", deposit: deposit + " zł" },
          firestore
        )
      );
    }

    setShowSuccessMessage(true);
  };

  //INPUTS
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setCard({ ...card, [name]: value });
  };

  //IMG INPUT
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);

    setImageToShowAfterChoosing(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <CardLayer>
      <CardLayerInner onClick={handleCloseButtonClick} />
      <CardAddContainer>
        <CardCloseIcon onClick={handleCloseButtonClick}>
          <Times />
        </CardCloseIcon>
        {!showSuccessMessage ? (
          <div>
            <form onSubmit={postDataHandler}>
              <FormContainer>
                <div>
                  <CardInputContainer>
                    <CardInput
                      name="brand"
                      type="text"
                      value={brand}
                      onChange={handleInputChange}
                      required
                      autoComplete="on"
                      placeholder="Marka"
                    />
                  </CardInputContainer>
                  <CardInputContainer>
                    <CardInput
                      name="fuel"
                      type="text"
                      value={fuel}
                      onChange={handleInputChange}
                      required
                      autoComplete="on"
                      placeholder="Paliwo"
                    />
                  </CardInputContainer>
                  <CardInputContainer>
                    <CardInput
                      name="engine"
                      type="text"
                      value={engine}
                      onChange={handleInputChange}
                      required
                      autoComplete="on"
                      placeholder="Silnik"
                    />
                  </CardInputContainer>
                </div>
                <div>
                  <CardInputContainer>
                    <CardInput
                      name="cost"
                      type={"number"}
                      min={"0"}
                      value={cost}
                      onChange={handleInputChange}
                      required
                      autoComplete="on"
                      placeholder="Koszt"
                    />
                    <span>zł</span>
                  </CardInputContainer>
                  <CardInputContainer>
                    <CardInput
                      name="deposit"
                      type={"number"}
                      min={"0"}
                      value={deposit}
                      onChange={handleInputChange}
                      required
                      autoComplete="on"
                      placeholder="Kaucja"
                    />
                    <span>zł</span>
                  </CardInputContainer>
                  <CheckboxContainer>
                    <span>Dostępność</span>
                    <Checkbox
                      checked={isAvailable}
                      onChange={() =>
                        setCard({ ...card, isAvailable: !isAvailable })
                      }
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: COLORS["color-green-main"],
                        },
                      }}
                    />
                  </CheckboxContainer>
                </div>
              </FormContainer>
              <ImageContainer>
                {!!imageUrl ? (
                  <img src={imageUrl} alt="Wybrane zjdęcię" />
                ) : !!imageToShowAfterChoosing ? (
                  <img
                    src={imageToShowAfterChoosing}
                    alt="Wybrane zjdęcię (plik)"
                  />
                ) : (
                  <span>Brak zdjęcia</span>
                )}
              </ImageContainer>
              <input
                className="custom-file-input"
                name="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                pfor="image"
                id="imgInp"
              />
              <progress value={progress} max="100" />
              <Buttons>
                {progress === 0 && !!image ? (
                  <JustAButton
                    progress={progress > 60 ? progress : null}
                    type="button"
                    onClick={
                      image && brand && fuel && id && engine && deposit && cost
                        ? handleImageUpload
                        : null
                    }
                  >
                    Wgraj zdjęcie
                  </JustAButton>
                ) : card.imageUrl ? (
                  <SubmitButton type="submit">Opublikuj</SubmitButton>
                ) : null}
              </Buttons>
            </form>
          </div>
        ) : (
          <SuccessMessageContainer>
            <p>{successMessage}</p>
            <p>
              <span>
                <Tick />
              </span>
            </p>
          </SuccessMessageContainer>
        )}
      </CardAddContainer>
    </CardLayer>
  );
};

export default CardAdd;
