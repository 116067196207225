import React from "react";
import {
  ErrorPageContainer,
  ErrorPageContent,
  ErrorPageDescr,
  ErrorPageTitle,
  ErrorPageWrapper,
} from "./error.styles";

import { BiError as Error } from "react-icons/bi";

export const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <ErrorPageContainer>
        <ErrorPageContent>
          <div>
            <ErrorPageTitle>Error</ErrorPageTitle>
            <Error />
          </div>
          <ErrorPageDescr>
            Podany przez Ciebie adres nie istnieje :)
          </ErrorPageDescr>
        </ErrorPageContent>
      </ErrorPageContainer>
    </ErrorPageWrapper>
  );
};
