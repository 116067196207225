import { css } from "styled-components";
import { COLORS } from "./StyleVariables";

export const SectionQueries = css`
  @media (max-width: 990px) {
    font-size: 34px;

    &:before {
      width: 100px;
      height: 3px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 450px) {
    font-size: 28px;

    &:before {
      width: 80px;
      height: 3px;
    }
  }
`;

export const colors = {
  light: "#74d680", //"#00c0f7",
  dark: "#67a26f", //"#0074f7",
};

export const HeroWrapperMinHeightSmall = css`
  min-height: 700px;
`;

export const HeroWrapperMinHeightBig = css`
  min-height: 900px;
`;

export const HeroContainerContentToEnd = css`
  display: flex;
  justify-content: flex-end;
`;

export const sectionDefaultStyles = css`
  color: ${COLORS["color-white-main"]};
  font-size: 16px;
  font-weight: 500;
`;

export const SectionContainer = css`
  max-width: 1300px;
  padding: 60px 30px;
  margin: 0 auto;
  width: calc(100% - 60px);

  @media (max-width: 460px) {
    padding: 30px 20px;
    width: calc(100% - 40px);
  }

  @media (max-width: 365px) {
    padding: 30px 15px;
    width: calc(100% - 30px);
  }
`;

export const SectionTitle = css`
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
  color: ${COLORS["color-white-light"]};
  margin: 0 0 50px 0;
  text-align: center;

  span {
    font-weight: 600;
    color: ${COLORS["color-green-main"]};
  }

  @media (max-width: 768px) {
    font-size: 36px;
    margin: 0 0 45px 0;
  }

  @media (max-width: 460px) {
    font-size: 28px;
    margin: 0 0 20px 0;
  }
`;
