import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import "./App.css";
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";
import { PrivateRoute } from "./components/private-route/private-route";
import Bolt from "./pages/bolt/bolt.component";
import Driver from "./pages/driver/driver";
import { ErrorPage } from "./pages/error/error.component";
import FreeNow from "./pages/free-now/free-now.component";
import Home from "./pages/home/home.component";
import Offer from "./pages/offer/offer.component";
import SigninAndSignupPage from "./pages/sign-in-and-sign-up/sign-in-and-sign-up.component";
import Uber from "./pages/uber/uber.component";
import Vehicles from "./pages/vehicles/vehicles.component";
import Work from "./pages/work/work.component";
import { checkUserSession } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selector";
const App = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/auth"
          element={
            <PrivateRoute currentUser={!!currentUser}>
              <SigninAndSignupPage />
            </PrivateRoute>
          }
        />
        <Route path="/offer" element={<Offer />} />
        <Route
          path="/offer/vehicles"
          element={<Vehicles currentUser={currentUser} />}
        />
        <Route path="/offer/driver" element={<Driver />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/uber" element={<Uber />} />
        <Route path="/work/bolt" element={<Bolt />} />
        <Route path="/work/free-now" element={<FreeNow />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
