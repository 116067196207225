import React from "react";
import { CardContainer, CardDescr, CardIcon, CardTitle } from "./card.styles";

const Card = ({ data }) => {
  const { title, descr, icon } = data;

  return (
    <CardContainer>
      <CardIcon>{icon}</CardIcon>
      <CardTitle>{title}</CardTitle>
      <CardDescr>{descr}</CardDescr>
    </CardContainer>
  );
};

export default Card;
