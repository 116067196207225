import React from "react";
import Benefits from "../../components/benefits/benefits.component";
import Contact from "../../components/contact/contact.component";
import Hero from "../../components/hero/hero.component";
import OurOffer from "../../components/our-offer/our-offer.component";
import Platforms from "../../components/platforms/platforms.component";

import WHY_DATA from "../../data/why.data";
import HERO_DATA from "../../data/hero.data";

const Offer = () => {
  return (
    <>
      <Hero data={HERO_DATA.offer} />
      <OurOffer title={"Nasza Oferta"} />
      <Benefits data={WHY_DATA} title={"Dlaczego Dacar Partner?"} />
      <Contact title={"Skontaktuj się"} />
      <Platforms />
    </>
  );
};

export default Offer;
