import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { emailSignInStart } from "../../redux/user/user.actions";

import { Form, FormContainer, FormContent, FormTitle } from "./sign-in.styles";
import { CustomInput } from "../custom-input/custom-input.component.jsx";
import { Button } from "@mui/material";
import { COLORS } from "../../StyleVariables";

const SignIn = () => {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const { email, password } = userData;
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(emailSignInStart({ email, password }));
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    setUserData({ ...userData, [name]: value });
  };

  return (
    <FormContainer>
      <FormContent>
        <FormTitle>Zaloguj Sie Do Dacar</FormTitle>
      </FormContent>
      <Form onSubmit={handleSubmit}>
        <CustomInput
          inputAttr={{
            name: "email",
            type: "email",
            value: email,
            onChange: handleChange,
          }}
          label={"E-mail"}
        />
        <CustomInput
          inputAttr={{
            name: "password",
            type: "password",
            value: password,
            onChange: handleChange,
          }}
          label={"Hasło"}
          isShowPassword={true}
        />
        <Button
          style={{
            backgroundColor: COLORS["color-green-main"],
            margin: "16px 0",
          }}
          variant="contained"
          type="submit"
        >
          Zaloguj się
        </Button>
      </Form>
    </FormContainer>
  );
};

export default SignIn;
