import styled from "styled-components";
import {
  HeroContainerContentToEnd,
  HeroWrapperMinHeightSmall,
  HeroWrapperMinHeightBig,
} from "../../GlobalStyles";

export const HeroWrapper = styled.section`
  background-image: ${({ imageUrl }) => `url('${imageUrl}')`};
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  min-height: 700px;
  padding: 106px 0 0 0;
  ${({ styleType }) =>
    styleType === "home" ? HeroWrapperMinHeightBig : HeroWrapperMinHeightSmall}

  @media (max-width: 768px) {
    min-height: auto;
  }
`;

export const HeroContainer = styled.div`
  padding: 30px;
  max-width: 1300px;
  margin: 0 auto;
  ${({ styleType }) => styleType === "bolt" && HeroContainerContentToEnd}

  @media (max-width: 768px) {
    padding: 15px;
  }
`;
