import styled from "styled-components";

export const TaxiAppsImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: row;
  }
`;

export const TaxiAppsImage = styled.div`
  border-radius: 20px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 5px;
  transition: all 200ms ease-out;

  img {
    width: 100%;
  }

  &:hover {
    transform: translate(0, -15px);
  }

  @media (max-width: 350px) {
    width: 65px;
    height: 65px;
  }
`;
