import React from "react";
import { TaxiAppsImage, TaxiAppsImages } from "./taxi-apps.styles";

import boltLogo from "../../assets/boltLogo.jpg";
import uberLogo from "../../assets/uberLogo.png";
import freenowLogo from "../../assets/freenowLogo.png";
import { Link } from "react-router-dom";

export const TaxiApps = () => (
  <TaxiAppsImages>
    <TaxiAppsImage>
      <Link to="/work/bolt">
        <img src={boltLogo} alt="Bolt Logo" />
      </Link>
    </TaxiAppsImage>
    <TaxiAppsImage>
      <Link to="/work/uber">
        <img src={uberLogo} alt="Uber Logo" />
      </Link>
    </TaxiAppsImage>
    <TaxiAppsImage>
      <Link to="/work/free-now">
        <img src={freenowLogo} alt="FreeNow Logo" />
      </Link>
    </TaxiAppsImage>
  </TaxiAppsImages>
);
