import React from "react";
import Contact from "../../components/contact/contact.component";
import { ForDriver } from "../../components/for-driver/for-driver";
import Hero from "../../components/hero/hero.component";

import HERO_DATA from "../../data/hero.data";
import { FOR_DRIVER } from "../../data/for-driver.data";
import Works from "../../components/works/works.component";
import Benefits from "../../components/benefits/benefits.component";
import BENEFITS_DATA from "../../data/benefits.data";

const Driver = () => {
  return (
    <>
      <Hero data={HERO_DATA["driver"]} />
      <ForDriver title={"Dla kierowców"} data={FOR_DRIVER} />
      <Works title={"Praca"} />
      <Benefits data={BENEFITS_DATA} title={"Korzyści ze Współpracy"} />
      <Contact title={"Skontaktuj się"} />
    </>
  );
};

export default Driver;
