import React from "react";
import Benefits from "../../components/benefits/benefits.component";
import Cars from "../../components/cars/cars.component";
import Contact from "../../components/contact/contact.component";
import Hero from "../../components/hero/hero.component";
import Works from "../../components/works/works.component";

import BENEFITS_DATA from "../../data/benefits.data";
import HERO_DATA from "../../data/hero.data";

const Vehicles = ({ currentUser }) => {
  return (
    <>
      <Hero data={HERO_DATA.vehicles} />
      <Cars title={"Kolekcja"} currentUser={currentUser} />
      <Works title={"Praca"} />
      <Benefits data={BENEFITS_DATA} title={"Korzyści ze Współpracy"} />
      <Contact title={"Skontaktuj się"} />
    </>
  );
};

export default Vehicles;
