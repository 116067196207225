import styled from "styled-components";
import { COLORS } from "../../StyleVariables.js";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  opacity: 1;
  border-radius: 8px;
  color: ${COLORS["color-white-dark"]};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 365px) {
    width: 100%;
  }
`;

export const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: start;
  align-items: center;
  height: 60px;
  width: calc(100% - 32px);
  padding: 0 16px;
  border-bottom: 1px solid rgba(220, 220, 220, 0.1);
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  color: ${COLORS["color-white-main"]};
  font-weight: 900;
  margin: 0 auto;
`;

export const FormCloseButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(220, 220, 220, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FormDescr = styled.p`
  align-self: flex-end;
`;
