import React, { useState } from "react";
import {
  CustomInputArea,
  CustomInputContainer,
  CustomInputLabel,
  CustomInputTogglePassword,
} from "./custom-input.styles.jsx";

import { HiEye as Eye, HiEyeOff as EyeOff } from "react-icons/hi";

export const CustomInput = ({ label, inputAttr, isShowPassword }) => {
  const [isPasswordDisplayed, setIsPasswordDisplayed] = useState(false);
  inputAttr.type = isShowPassword && !isPasswordDisplayed ? "password" : "text";

  return (
    <CustomInputContainer isFilled={!!inputAttr.value}>
      <CustomInputArea
        {...inputAttr}
        isFilled={!!inputAttr.value}
        autoFocus
        autoComplete="off"
        required
      />
      <CustomInputLabel isFilled={!!inputAttr.value}>{label}</CustomInputLabel>
      {!!isShowPassword && (
        <CustomInputTogglePassword
          onClick={() => setIsPasswordDisplayed(!isPasswordDisplayed)}
          type="button"
        >
          {isPasswordDisplayed ? <Eye /> : <EyeOff />}
        </CustomInputTogglePassword>
      )}
    </CustomInputContainer>
  );
};
