import React from "react";
import Partner from "../../components/partner/partner.component";
import Conditions from "../../components/conditions/conditions.component";
import How from "../../components/how/how.component";
import Cooperation from "../../components/cooperation/cooperation.component";
import Contact from "../../components/contact/contact.component";
import Hero from "../../components/hero/hero.component";

import HERO_DATA from "../../data/hero.data";
import HOW_DATA from "../../data/how.data";
import COOPERATION_DATA from "../../data/cooperation.data";
import PARTNER_DATA from "../../data/partner.data";
import CONDITIONS_DATA from "../../data/conditions.data";

const Bolt = () => {
  return (
    <>
      <Hero data={HERO_DATA.bolt} />
      <Partner data={PARTNER_DATA.bolt} />
      <Conditions data={CONDITIONS_DATA.bolt} />
      <How data={HOW_DATA.bolt} />
      <Contact title={"Skontaktuj się"} />
      <Cooperation data={COOPERATION_DATA.bolt} />
    </>
  );
};

export default Bolt;
