import { takeLatest, call, put, all } from "redux-saga/effects";

import {
  firestore,
  convertCardsSnapshotToMap,
} from "../../firebase/firebase.utils";

import {
  fetchCardsSuccess,
  fetchCardsFailure,
  addCardFailure,
  addCardSuccess,
  editCardSuccess,
  editCardFailure,
  deleteCardSuccess,
  deleteCardFailure,
} from "./cards.actions";

import CardsActionTypes from "./cards.types";

export function* fetchCardsAsync() {
  try {
    const cardsRef = firestore.collection("cards");

    const cardsSnapshot = yield cardsRef.get();
    const cardsMap = yield call(convertCardsSnapshotToMap, cardsSnapshot);

    yield put(fetchCardsSuccess(cardsMap));
  } catch (error) {
    yield put(fetchCardsFailure(error));
  }
}

export function* addCardAsync({ payload: { card, firestore } }) {
  try {
    const docRef = firestore.collection("cards");
    docRef.doc(`${card.id}`).set(card);

    const successMessage = "Samochód został dodany!";
    yield put(addCardSuccess(card, successMessage));
  } catch (error) {
    yield put(addCardFailure(error));
  }
}

export function* editCardAsync({ payload: { card, firestore } }) {
  try {
    const docRef = firestore.collection("cards");
    docRef.doc(`${card.id}`).set(card);

    const successMessage = "Zmiany samochodu zostały dokonane!";
    yield put(editCardSuccess(card, successMessage));
  } catch (error) {
    yield put(editCardFailure(error));
  }
}

export function* deleteCardAsync({ payload: { card, firestore } }) {
  try {
    const docRef = firestore.collection("cards");
    docRef.doc(`${card.id}`).delete();

    const successMessage = "Samochód został pomyślnie usunięty!";
    yield put(deleteCardSuccess(card, successMessage));
  } catch (error) {
    yield put(deleteCardFailure(error));
  }
}

export function* onFetchCardsStart() {
  yield takeLatest(CardsActionTypes.FETCH_CARDS_START, fetchCardsAsync);
}

export function* onAddCardStart() {
  yield takeLatest(CardsActionTypes.ADD_CARD_START, addCardAsync);
}

export function* onEditCardStart() {
  yield takeLatest(CardsActionTypes.EDIT_CARD_START, editCardAsync);
}

export function* onDeleteCardStart() {
  yield takeLatest(CardsActionTypes.DELETE_CARD_START, deleteCardAsync);
}

export function* onFetchAfterAddCardSuccess() {
  yield takeLatest(CardsActionTypes.ADD_CARD_SUCCESS, fetchCardsAsync);
}

export function* onFetchAfterEditCardSuccess() {
  yield takeLatest(CardsActionTypes.EDIT_CARD_SUCCESS, fetchCardsAsync);
}

export function* onFetchAfterDeleteCardSuccess() {
  yield takeLatest(CardsActionTypes.DELETE_CARD_SUCCESS, fetchCardsAsync);
}

export function* cardsSagas() {
  yield all([
    call(onFetchCardsStart),
    call(onAddCardStart),
    call(onEditCardStart),
    call(onDeleteCardStart),
    call(onFetchAfterAddCardSuccess),
    call(onFetchAfterEditCardSuccess),
    call(onFetchAfterDeleteCardSuccess),
  ]);
}
