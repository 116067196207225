import styled from "styled-components";

export const CardContainer = styled.div`
  padding: 15px;
  border-radius: 20px;
  background: linear-gradient(
    to left top,
    rgba(248, 248, 248, 0.5),
    rgba(248, 248, 248, 0.7)
  );
  box-shadow: 6px 6px 20px rgba(48, 48, 48, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100% - 30px);
  position: relative;
  overflow: hidden;
`;

export const CardIcon = styled.div`
  border-radius: 50%;
  background: linear-gradient(135deg, #74d680, #67a26f);
  padding: 20px;
  font-size: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1f1f1;
  margin: 0 0 15px 0;
`;

export const CardTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 15px 0;
  color: #414141;
`;

export const CardDescr = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #515151;
`;
