import React from "react";
import { BenefitsTitle } from "../benefits/benefits.styles";

import {
  ForDriverContainer,
  ForDriverWrapper,
  BenefitsCircle1,
  BenefitsCircle2,
  BenefitsCircle3,
  BenefitsCircle4,
  ForDriverContent,
  ForDriverContentInner,
  ForDriverDescr,
  ForDriverImg,
  ForDriverSubtitle,
} from "./for-driver.styles";
import forDriver from "../../assets/forDriver.png";
import { TaxiApps } from "../taxi-apps/taxi-apps";

export const ForDriver = ({ data, title }) => {
  const { subtitle, descr } = data;
  return (
    <ForDriverWrapper>
      <ForDriverContainer>
        <BenefitsTitle>{title}</BenefitsTitle>

        <ForDriverContent>
          <ForDriverContentInner>
            <ForDriverSubtitle>{subtitle}</ForDriverSubtitle>
            <ForDriverDescr>{descr}</ForDriverDescr>
            <TaxiApps />
          </ForDriverContentInner>

          <ForDriverImg>
            <img src={forDriver} alt="for-driver" />
          </ForDriverImg>
        </ForDriverContent>
        <BenefitsCircle1 />
        <BenefitsCircle2 />
        <BenefitsCircle3 />
        <BenefitsCircle4 />
      </ForDriverContainer>
    </ForDriverWrapper>
  );
};
