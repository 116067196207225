import React, { useState } from "react";
import {
  BenefitsCircle1,
  BenefitsCircle2,
  BenefitsCircle3,
  BenefitsCircle4,
  BenefitsTitle,
} from "../benefits/benefits.styles";
import {
  ContactButton,
  ContactContainer,
  ContactForm,
  ContactInput,
  ContactTextarea,
  ContactWrapper,
  SuccessMessage,
} from "./contact.styles";
import emailjs from "emailjs-com";
import { useRef } from "react";
import { SuccessMessageContainer } from "../add-card/add-card.styles";
import { FaSkyatlas as Tick } from "react-icons/fa";

const Contact = ({ title, contactRef }) => {
  const form = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState({ message: "", type: undefined });
  const [showMessage, setShowMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "dacar_on_gmail_1",
        "dacar_gmail_template_1",
        form.current,
        "39vdoLFMztvwNpg9D"
      )
      .then(
        (result) => {
          setResponse({ message: result.text, type: "success" });
          document.body.style.overflow = "hidden";
          setShowMessage(true);
        },
        (error) => {
          setResponse({ message: error.text, type: "error" });
          document.body.style.overflow = "unset";
          setShowMessage(true);
        }
      );

    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <ContactWrapper ref={contactRef} id="contact">
      {response.type === "success" &&
        response.message === "OK" &&
        showMessage && (
          <SuccessMessage>
            <div
              onClick={() => {
                document.body.style.overflow = "unset";
                setShowMessage(false);
              }}
            />
            <SuccessMessageContainer>
              <p>Wiadmość została wysłana</p>
              <p>
                <span>
                  <Tick />
                </span>
              </p>
            </SuccessMessageContainer>
          </SuccessMessage>
        )}

      <ContactContainer>
        <BenefitsTitle>{title}</BenefitsTitle>

        <ContactForm onSubmit={sendEmail} ref={form}>
          <ContactInput
            name="firstName"
            type="text"
            placeholder="Imię"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <ContactInput
            name="lastName"
            type="text"
            placeholder="Nazwisko"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <ContactInput
            name="email"
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <ContactTextarea
            name="message"
            type="text"
            placeholder="Wiadomość"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <ContactButton type="submit">Wyślij wiadomość</ContactButton>
        </ContactForm>

        <BenefitsCircle1 />
        <BenefitsCircle2 />
        <BenefitsCircle3 />
        <BenefitsCircle4 />
      </ContactContainer>
    </ContactWrapper>
  );
};

export default Contact;
