import { Grid } from "@mui/material";
import React from "react";
import Card from "../card/card.component";

import {
  BenefitsCircle1,
  BenefitsCircle2,
  BenefitsCircle3,
  BenefitsCircle4,
  BenefitsContainer,
  BenefitsTitle,
  BenefitsWrapper,
} from "./benefits.styles";

const Benefits = ({ title, data }) => {
  return (
    <BenefitsWrapper>
      <BenefitsContainer>
        <BenefitsTitle>{title}</BenefitsTitle>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {data.map((card, index) => (
            <Grid
              key={index}
              item
              md={4}
              sm={6}
              xs={12}
              style={{ zIndex: "1" }}
            >
              <Card data={card} />
            </Grid>
          ))}
        </Grid>

        <BenefitsCircle1 />
        <BenefitsCircle2 />
        <BenefitsCircle3 />
        <BenefitsCircle4 />
      </BenefitsContainer>
    </BenefitsWrapper>
  );
};

export default Benefits;
