import React from "react";

import SignIn from "../../components/sign-in/sign-in.component";
import {
  Blur,
  Layout,
  SignInAndSignUpContainer,
  SignInAndSignUpContent,
  SignInAndSignUpWrapper,
} from "./sign-in-and-sign-up.styles";
import highway from "../../assets/highway.jpg";

const SigninAndSignupPage = () => (
  <SignInAndSignUpWrapper>
    <SignInAndSignUpContainer>
      <SignInAndSignUpContent>
        <SignIn />
      </SignInAndSignUpContent>
      <Layout>
        <img src={highway} alt="Highway" />
      </Layout>
      <Blur />
    </SignInAndSignUpContainer>
  </SignInAndSignUpWrapper>
);

export default SigninAndSignupPage;
