import styled from "styled-components";
import { COLORS } from "../../StyleVariables";

export const ContactWrapper = styled.section`
  background: linear-gradient(0deg, #393b44, #737b84);
  position: relative;
  overflow: hidden;
`;
export const ContactContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ContactForm = styled.form`
  padding: 15px;
  max-width: 700px;
  width: 100%;
  flex-grow: 100;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  background: linear-gradient(
    to left top,
    rgba(248, 248, 248, 0.5),
    rgba(248, 248, 248, 0.7)
  );
  border-radius: 20px;
  box-shadow: 6px 6px 20px rgba(48, 48, 48, 0.2);
  z-index: 1;
  text-align: center;
  padding: 10px 18px;

  @media (max-width: 768px) {
    min-width: calc(100% - 30px);
  }
`;

export const ContactInput = styled.input`
  color: #282828;
  border-radius: 8px;
  background: linear-gradient(
    to left top,
    rgba(248, 248, 248, 0.3),
    rgba(248, 248, 248, 0.5)
  );
  box-shadow: 6px 6px 20px rgba(48, 48, 48, 0.2);
  padding: 8px 16px;
  width: calc(100% - 32px);
  margin: 8px 0;
  border: rgba(220, 220, 220, 0) solid 3px;
  transition: all 150ms ease-out;

  &:focus,
  &:active {
    border: rgba(218, 218, 218, 0.95) solid 3px;
  }
`;

export const ContactTextarea = styled.textarea`
  color: #282828;
  border-radius: 8px;
  background: linear-gradient(
    to left top,
    rgba(248, 248, 248, 0.3),
    rgba(248, 248, 248, 0.5)
  );
  box-shadow: 6px 6px 20px rgba(48, 48, 48, 0.2);
  padding: 8px 16px;
  margin: 8px 0;
  min-height: 250px;
  max-height: 250px;
  max-width: calc(100% - 32px);
  min-width: calc(100% - 32px);
  border: rgba(218, 218, 218, 0) solid 3px;
  transition: all 150ms ease-out;

  &:focus,
  &:active {
    border: rgba(218, 218, 218, 0.95) solid 3px;
  }
`;

export const ContactButton = styled.button`
  color: #282828;
  border-radius: 8px;
  background-color: rgba(115, 214, 126, 0.9);
  box-shadow: 6px 6px 20px rgba(48, 48, 48, 0.2);
  padding: 12px 30px;
  margin: 25px 0 8px 0;
  justify-self: flex-end;
  font-size: 18px;
  font-weight: 600;
  color: #f1f1f1;
  transition: all 100ms ease-out;
  width: 100%;
  max-width: 300px;

  &:hover {
    transform: translate(10px, 0);
    background-color: rgba(115, 214, 126, 0.7);
    color: #fff;
  }
`;

export const SuccessMessage = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.9);
  z-index: 999999999999999999999999999999999;

  div:first-of-type {
    z-index: 999999999999999999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  div:last-of-type {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999999999999999999999999;
    background-color: #303030;
    padding: 25px 10px 10px 10px;
    border-radius: 7px;
    border: 4px solid ${COLORS["color-green-light"]};
    min-width: 300px;
    transition: all 350ms ease-out;
    box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.75);
    max-width: 350px;
  }
`;
