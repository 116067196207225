import React from "react";
import { HeroContainer, HeroWrapper } from "./hero.styles";

import HeroBox from "../hero-box/hero-box.component";

const Hero = ({ data }) => {
  const { title, descr, button, imageUrl, id } = data;

  return (
    <HeroWrapper styleType={id} imageUrl={imageUrl}>
      <HeroContainer styleType={id}>
        <HeroBox title={title} descrMap={descr} buttonText={button} />
      </HeroContainer>
    </HeroWrapper>
  );
};

export default Hero;
