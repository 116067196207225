import React from "react";
import Benefits from "../../components/benefits/benefits.component";
import Contact from "../../components/contact/contact.component";
import Works from "../../components/works/works.component";
import Hero from "../../components/hero/hero.component";

import BENEFITS_DATA from "../../data/benefits.data";
import HERO_DATA from "../../data/hero.data";

const Work = () => {
  return (
    <>
      <Hero data={HERO_DATA.work} />
      <Works title={"Praca"} />
      <Benefits data={BENEFITS_DATA} title={"Korzyści ze Współpracy"} />
      <Contact title={"Skontaktuj się"} />
    </>
  );
};

export default Work;
