import styled from "styled-components";
import { SectionContainer } from "../../GlobalStyles";

export const SignInAndSignUpWrapper = styled.section`
  padding: 130px 0 0 0;
  overflow: hidden;
  position: relative;

  @media (max-width: 990px) {
    padding: 70px 0 0 0;
  }
`;

export const SignInAndSignUpContainer = styled.div`
  ${SectionContainer}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 340px);

  @media (max-width: 990px) {
    height: calc(100vh - 340px - (-113px + 90px - 25px));
  }

  @media (max-width: 768px) {
    height: calc(100vh - 340px - (-113px + 90px - 25px) - 18px);
  }

  @media (max-width: 466px) {
    height: calc(100vh - 340px - (-113px + 90px - 25px) - 36px);
  }

  @media (max-width: 460px) {
    height: calc(100vh - 340px - (-113px + 90px - 49px));
  }
`;

export const SignInAndSignUpContent = styled.div`
  background-color: rgba(46, 46, 46, 0.85);
  box-shadow: -1px 3px 5px -3px rgba(0, 0, 0, 0.75);
  padding: 16px;
  border-radius: 5px;
`;

export const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
`;
