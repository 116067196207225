import React, { useEffect, useState } from "react";
import CarDetails from "../cars-car-details/cars-car-details.component";
import { firestore } from "../../firebase/firebase.utils";

import { AnimatePresence } from "framer-motion";
import Car from "../cars-car/cars-car.component";
import {
  ButtonContainer,
  CarsCards,
  CarsContainer,
  CarsWrapper,
} from "./cars.styles";

import { BenefitsTitle } from "../benefits/benefits.styles";
import { Button } from "@mui/material";
import { COLORS } from "../../StyleVariables";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCardStart,
  fetchCardsStart,
} from "../../redux/cards/cards.actions";
import { selectCards } from "../../redux/cards/cards.selectors";
import CardAdd from "../add-card/add-card.component";

const Cars = ({ title, currentUser }) => {
  const [isAddCardOpened, setIsCardOpened] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const dispatch = useDispatch();
  const cars = useSelector(selectCards);

  const handleCloseButtonClick = () => {
    setIsCardOpened(false);
    setCurrentCard(null);
    document.body.style.overflow = "unset";
    setShowSuccessMessage(false);
  };

  const handleAddCardButtonClick = () => {
    setIsCardOpened(true);
    document.body.style.overflow = "hidden";
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    document.body.style.overflow = "unset";
  };

  const handleDetailsOpen = () => {
    setDetailsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openEdit = (carToEdit) => {
    document.body.style.overflow = "hidden";
    setCurrentCard(carToEdit);
    setIsCardOpened(true);
  };

  const deleteCar = (carToDelete) => {
    dispatch(deleteCardStart(carToDelete, firestore));
  };

  useEffect(() => {
    dispatch(fetchCardsStart());
  }, [dispatch]);

  return (
    <CarsWrapper>
      {!!currentUser && isAddCardOpened && currentCard === null && (
        <CardAdd
          handleCloseButtonClick={handleCloseButtonClick}
          showSuccessMessage={showSuccessMessage}
          setShowSuccessMessage={setShowSuccessMessage}
        />
      )}
      {!!currentUser && isAddCardOpened && currentCard && (
        <CardAdd
          handleCloseButtonClick={handleCloseButtonClick}
          currentCard={currentCard}
          showSuccessMessage={showSuccessMessage}
          setShowSuccessMessage={setShowSuccessMessage}
        />
      )}
      <CarsContainer>
        <BenefitsTitle>{title}</BenefitsTitle>
        <CarsCards>
          {cars.map((car, index) => (
            <Car
              key={index}
              car={car}
              setSelectedCarIndex={setSelectedCarIndex}
              carIndex={index}
              onDetailsOpen={handleDetailsOpen}
              openEdit={openEdit}
              currentUser={currentUser}
              deleteCar={deleteCar}
            />
          ))}
        </CarsCards>
      </CarsContainer>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {detailsOpen && (
          <CarDetails
            detailsOpen={detailsOpen}
            handleClose={handleDetailsClose}
            car={cars[selectedCarIndex]}
            setSelectedCarIndex={setSelectedCarIndex}
          />
        )}
      </AnimatePresence>

      {!!currentUser && (
        <ButtonContainer>
          <Button
            style={{
              backgroundColor: COLORS["color-green-main"],
              margin: "16px 0",
              fontSize: "18px",
              fontWeight: "700",
              padding: "10px 18px",
            }}
            variant="contained"
            type="submit"
            onClick={handleAddCardButtonClick}
          >
            Dodaj Auto
          </Button>
        </ButtonContainer>
      )}
    </CarsWrapper>
  );
};

export default Cars;
