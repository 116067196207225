export const COLORS = {
  "color-green-main": "#67a26f",
  "color-green-light": "#74d680",
  "color-blue-normal": "#619ffc",
  "color-blue-dark": "#1152b3",
  "color-black-main": "#292929",
  "color-black-light": "#282828",
  "color-black-dark": "#171717",
  "color-black-super-dark": "#0f0f0f",
  "color-black-opacity": "#282828ee",
  "color-white-main": "#fefefe",
  "color-white-light": "#ededed",
  "color-white-dark": "#f4eae0",
  "color-white-blue": "#d6eaff",
};
