import styled from "styled-components";
import { COLORS } from "../../StyleVariables";
import CustomButton from "../custom-button/custom-button.component";

export const CardLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.9);
  z-index: 999999999999999999999999999999999;
`;

export const CardLayerInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const CardAddContainer = styled.div`
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #303030;
  padding: 25px 10px 10px 10px;
  border-radius: 7px;
  border: 4px solid ${COLORS["color-green-light"]};
  min-width: 300px;
  transition: all 350ms ease-out;
  box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.75);

  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }

  progress[value] {
    width: 100%;
    height: 35px;
  }
`;

export const FormContainer = styled.div`
  display: flex;

  > div {
    &:first-of-type {
      margin: 0 10px 0 0;
    }

    &:last-of-type {
      max-width: 120px;
    }
  }
`;

export const CardInputContainer = styled.div`
  position: relative;
  margin: 10px 0;

  span {
    display: block;
    position: absolute;
    top: 0px;
    right: 18px;
    color: #999;
    background-color: #cdcdcd;
    width: 25px;
    padding: 11px 0px 11px 4px;
  }
`;

export const CardInput = styled.input`
  background: none;
  background-color: #cdcdcd;
  color: ${COLORS["color-black-main"]};
  font-size: 14px;
  padding: 8px 4px;
  display: block;
  width: calc(100% - 8px);
  border: none;
  border-radius: 7px;
  border-bottom: 4px solid ${COLORS["color-green-main"]};
  box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.75);

  &:focus {
    outline: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const SubmitButton = styled(CustomButton)`
  &[type="submit"] {
    margin: 0 0 0 auto;
    min-width: 160px;
    min-width: 50%;
  }
`;

export const JustAButton = styled(CustomButton)`
  &[type="button"] {
    margin: 0 auto 0 0;
    min-width: 160px;
    min-width: 50%;

    &:hover {
      background-color: ${COLORS["color-green-light"]};
    }
  }
`;

export const CardCloseIcon = styled.div`
  position: absolute;
  top: 4px;
  right: 7.5px;
  z-index: 3;
  color: ${COLORS["color-green-light"]};
  overflow: hidden;
  transition: all 350ms ease-out;
  cursor: pointer;

  &:hover {
    svg {
      border-radius: 50%;
      color: #303030;
      background-color: ${COLORS["color-green-light"]};
    }
  }

  svg {
    transition: all 350ms ease-out;
    font-size: 25px;
  }
`;

export const CardResizeIcon = styled.div`
  pointer-events: none;
  font-size: 30px;
  position: absolute;
  bottom: 275px;
  right: 24px;
  z-index: 4;
  color: #535353;
`;

export const SuccessMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #f1f1f1;

  p {
    width: 100%;
    font-size: 34px;
    font-weight: 700;
    margin: 20px;
    text-align: center;

    span {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 74px;
        color: #f1f1f1;
      }
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #cdcdcd;
  font-size: 14px;
  padding: 8px 4px;
  width: calc(100% - 8px);
  border: none;
  margin: 10px 0;
  border-radius: 7px;
  border-bottom: 4px solid ${COLORS["color-green-main"]};
  box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.75);

  &:focus {
    outline: none;
  }

  > span {
    padding: 0;

    &:last-of-type {
      margin: 0 0 0 5px;
      justify-self: flex-end;
    }
  }
`;

export const ImageContainer = styled.div`
  margin: 10px 0 0 0;
  font-size: 16px;
  background-color: #262626;
  border-radius: 13px;
  height: 150px;
  width: 100%;
  box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  overflow: hidden;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #adadad;
    font-weight: 700;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
