import CardsActionTypes from "./cards.types";

const INITIAL_STATE = {
  cards: [],
  isFetching: false,
  isPosting: false,
  errorMessage: undefined,
  successMessage: undefined,
};

const {
  FETCH_CARDS_START,
  FETCH_CARDS_SUCCESS,
  FETCH_CARDS_FAILURE,
  ADD_CARD_START,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
  EDIT_CARD_START,
  EDIT_CARD_SUCCESS,
  EDIT_CARD_FAILURE,
  DELETE_CARD_START,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
} = CardsActionTypes;

const cardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CARDS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CARDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        cards: action.payload,
      };
    case FETCH_CARDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case ADD_CARD_START:
    case EDIT_CARD_START:
    case DELETE_CARD_START:
      return {
        ...state,
        isPosting: true,
        successMessage: undefined,
        errorMessage: undefined,
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        cards: [...state.cards, action.payload.card],
        isPosting: false,
        successMessage: action.payload.successMessage,
        errorMessage: undefined,
      };
    case EDIT_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.map((card) =>
          card.id === action.payload.card.id ? action.payload.card : card
        ),
        isPosting: false,
        successMessage: action.payload.successMessage,
        errorMessage: undefined,
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.filter(
          (card) => !(card.id === action.payload.card.id)
        ),
        isPosting: false,
        successMessage: action.payload.successMessage,
        errorMessage: undefined,
      };
    case ADD_CARD_FAILURE:
    case EDIT_CARD_FAILURE:
    case DELETE_CARD_FAILURE:
      return {
        ...state,
        isPosting: false,
        successMessage: undefined,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default cardsReducer;
