import React from "react";

import logo from "../../assets/logoGreen.png";
import {
  FooterAuthor,
  FooterContainer,
  FooterImg,
  FooterLink,
  FooterLogo,
  FooterLogoContainer,
  FooterLogoText,
  FooterLogoTitle,
  FooterMenu,
  FooterWrapper,
} from "./footer.styles";

import { AiFillHeart as Heart } from "react-icons/ai";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLogoContainer to="/">
          <FooterLogo>
            <FooterImg>
              <img src={logo} alt="Logo" />
            </FooterImg>
            <FooterLogoText>
              <FooterLogoTitle>Dacar</FooterLogoTitle>
            </FooterLogoText>
          </FooterLogo>
          <FooterLogoTitle>Partner</FooterLogoTitle>
        </FooterLogoContainer>

        <FooterMenu>
          <FooterLink to="/work">Praca</FooterLink>
          <FooterLink to="/work/uber">Uber</FooterLink>
          <FooterLink to="/work/bolt">Bolt</FooterLink>
          <FooterLink to="/work/free-now">Free Now</FooterLink>
          <FooterLink to="/offer">Oferta</FooterLink>
          <FooterLink to="/offer/driver">Kierowca</FooterLink>
          <FooterLink to="/offer/vehicles">Pojazdy</FooterLink>
          <FooterLink to="/auth">Admin</FooterLink>
        </FooterMenu>

        <FooterAuthor>
          <span> Created with</span> <Heart /> <span>by Davit Hunanyan</span>
        </FooterAuthor>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
